.accordion {
  font-size: 18px;
  line-height: 32px;

  &.active {
    button {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  button {
    font-family: "HelveticaNeueW01-65Medi";
    color: $ot-red;

    &:hover {
      background-color: $secondary-red-hover;

      span {
        text-decoration: underline;
      }
    }

    &:active {
      background-color: $secondary-red-active;
    }
  }

  .content {
    display: none;
    margin-top: 8px;

    ul {
      color: #333;
      font-family: "HelveticaNeueW01-45Ligh";
    }

    &.open {
      display: block;
    }
  }
}
