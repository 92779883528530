.progress-container {
  position: relative;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 767px) {
    margin-bottom: 24px;
  }

  .inner {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 8px;
  }

  progress {
    border-radius: 4px;
    width: 100%;
    height: 8px;
    position: relative;
    overflow: hidden;
    vertical-align: baseline;
    border: 1px solid #b8babd;

    &::-moz-progress-bar {
      background-color: transparent;
      border-radius: 7px;
    }

    &::-webkit-progress-bar {
      background-color: #f2f0ef;
      border-radius: 7px;
    }

    &::-webkit-progress-value {
      background-color: transparent;
      height: 8px;
      border-radius: 7px;
      position: relative;
      top: -1px;
      background-color: #f2f0ef;
    }

    &::-moz-progress-value {
      height: 8px;
      border-radius: 7px;
      position: relative;
      top: 0px;
    }
  }

  .progress-text {
    color: $secondary-text;
    font-size: 18px;
    margin-top: -2px;
    line-height: 32px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: right;
    // width: 42px;
  }

  .progress-line {
    position: absolute;
    color: #fff;
    z-index: 1;
    left: 0;
    margin: auto;
    z-index: 1;
    text-align: center;
    height: 8px;
    background-color: $green;
    border-radius: 7px;
    top: 0;
    bottom: 0px;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
  }
}
