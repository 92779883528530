@import "../variables/variables";

body {
  input,
  textarea,
  select {
    font-family: "HelveticaNeueW01-65Medi";
    background: #ffffff;
    font-size: 18px;
    border: 1px solid #868686;
    border-radius: 4px;
    line-height: 16px;
    width: 100%;
    max-width: 345px;
    padding: 0.78rem;
    margin-bottom: 32px;

    &[type="radio"] {
      margin-bottom: 0;

      &:hover,
      &:focus,
      &.has-error {
        outline: none;
      }
    }

    &:hover,
    &:focus,
    &.has-error {
      outline-offset: -1px;
    }
    &.has-error {
      outline: 1px solid $ot-red;

      &:hover {
        outline: 2px solid $ot-red;
      }
    }
    &:hover {
      outline: 2px solid $hover;
    }
    &:focus {
      outline: 2px solid $focus;

      &:hover {
        outline: 2px solid $focus;
      }
    }

    &.short {
      max-width: 244px;
    }
    &.long {
      max-width: 345px;
    }
    &[type="date"] {
      -webkit-appearance: none;
      min-height: 48px;
    }
    &[type="checkbox"] {
      // margin-top: 5px;
      width: 36px;
      height: 36px;

      &:hover,
      &.focus,
      &:focus-within {
        outline: 0;
      }
    }
  }

  .input-wrapper {
    position: relative;
    margin-bottom: 32px;

    &.short {
      max-width: 244px;
    }
    &.long {
      max-width: 345px;
    }

    input {
      margin-bottom: 0;

      &:focus {
        + .clear-button {
          opacity: 1;
        }
      }
    }

    .clear-button {
      position: absolute;
      right: 12px;
      top: 10px;
      opacity: 0;
      padding: 0 4px;

      &:focus {
        opacity: 1;
      }

      svg {
        font-size: 26px;
        color: $darkgrey;
      }
    }
  }

  .checkbox-label {
    display: block;
    font-family: "HelveticaNeueW01-55Roma";
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    max-width: 600px;

    &:focus-visible {
      outline: 2px solid $focus;
      outline-offset: 4px;
    }
  }

  textarea {
    overflow: hidden;
    resize: none;
    max-width: none;
    min-height: 104px;
    line-height: 32px;
  }

  .textarea-label {
    margin-bottom: 0px;
  }

  .remaining-chars {
    font-size: 12px;
    line-height: 16px;
    color: $secondary-text;
    margin-bottom: 8px;
  }

  legend {
    margin-bottom: 10px;
  }

  select {
    padding-right: 15px;
  }

  // label {
  //   &.required {
  //     > span:not(.more-info) {
  //       display: inline;
  //       &::after {
  //         content: " *";
  //       }
  //     }
  //   }
  // }

  .required-info {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  label,
  legend,
  .file-list {
    display: block;
    font-family: "bittermedium";
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;

    .more-info {
      color: $darkgrey;
      font-size: 12px;
      line-height: 16px;
      margin-top: 8px;
    }

    .validation-error {
      margin-bottom: 0;
    }

    > span {
      display: block;
    }
  }

  .radio-group {
    display: inline-flex;
    flex-direction: column;

    > div {
      position: relative;

      &.has-error {
        border-left: 4px solid $ot-red;
      }
    }

    input[type="radio"] {
      width: 20px;
      height: 20px;
      position: absolute;
      accent-color: $ot-red;
      left: 10px;
      top: 15px;

      &:focus {
        + label {
          text-decoration: underline;
          background-color: $gray;
        }
      }

      &:focus-visible {
        + label {
          outline: 2px solid $focus;
        }
      }

      &:checked {
        outline: 0;

        + label {
          text-decoration: underline;
        }
      }
    }

    label {
      font-size: 18px;
      line-height: 32px;
      font-family: "HelveticaNeueW01-55Roma";
      margin: 0;
      border-radius: 4px;
      padding: 8px 0;
      padding-left: 46px;
      padding-right: 10px;
      // width: 100%;

      &:hover,
      &:focus {
        text-decoration: underline;
        background-color: $gray;
      }

      div {
        padding: 5px;
        // display: inline-flex;
      }
    }
  }

  .validation-error {
    display: inline-block;
    line-height: 24px;
    background-color: $yellow;
    font-family: "HelveticaNeueW01-55Roma";
    padding: 8px 16px;
    padding-left: 12px;
    border-left: 4px solid $ot-red;
    margin-top: 7px;
    margin-bottom: 15px;

    &.error-info {
      display: block;
      position: relative;
      background-color: $lightblue;
      border-color: $hover;
      margin-left: 50px;

      svg {
        top: 0;
        bottom: 0;
        margin: auto;
        width: 16px;
        height: 16px;
        padding: 4px;
        font-size: 16px;
        left: -50px;
        position: absolute;
        display: block;
        border-radius: 50%;
        background-color: $lightblue;
      }
    }
  }

  .summary-section {
    .summary-edit {
      align-items: end;
      & button {
        padding: 0;
      }
    }
  }
}

.box {
  input,
  textarea {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.input-info-text {
  font-family: "HelveticaNeueW01-55Roma";
}

.error-box {
  background-color: $lightpink;
  padding: 16px 24px;
  border-left: 4px $ot-red solid;
  margin-bottom: 40px;

  ul:not(.file-list) {
    font-size: 16px;
    line-height: 24px;

    a {
      text-decoration: underline;
      color: $text;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
.spinner {
  text-align: center;
  padding: 50px 0 70px;

  > div {
    font-size: 16px;
    // margin-top: 20px;d
    font-family: "HelveticaNeueW01-65Medi";
    line-height: 24px;
  }

  img {
    width: 64px;
    margin: auto;
  }

  svg {
    color: $ot-red;
    font-size: 64px;
  }
}
