@import "../variables/variables";
$icomoon-font-path: "./";

@font-face {
  font-family: "ogt-icons";
  src: url("../../assets/OgtIcons/ogt-icons.eot");

  src: url("../../assets/OgtIcons/ogt-icons.ttf") format("truetype"),
    url("../../assets/OgtIcons/ogt-icons.woff") format("woff"),
    url("../../assets/OgtIcons/ogt-icons.svg") format("svg");

  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ogt-"],
[class*=" ogt-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "ogt-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-link {
  svg {
    color: $ot-red;
  }
  i {
    &::before {
      display: inline-block;
      font-size: inherit;
      color: $ot-red;
    }

    &.flip {
      &::before {
        display: inline-block;
        transform: scaleX(-1);
      }
    }
  }
}

.ogt-PDF {
  &:before {
    content: $ogt-PDF;
  }
}
.ogt-info-uppdaterad-2 {
  &:before {
    content: $ogt-info-uppdaterad-2;
  }
}
.ogt-info-uppdaterad {
  &:before {
    content: $ogt-info-uppdaterad;
  }
}
.ogt-service-walk-32 {
  &:before {
    content: $ogt-service-walk-32;
  }
}
.ogt-map-icon {
  &:before {
    content: $ogt-map-icon;
  }
}
.ogt-shortcut-card-big {
  &:before {
    content: $ogt-shortcut-card-big;
  }
}
.ogt-accessibility {
  &:before {
    content: $ogt-accessibility;
  }
}
.ogt-info-circle {
  &:before {
    content: $ogt-info-circle;
  }
}
.ogt-extern-link-arrow {
  &:before {
    content: $ogt-extern-link-arrow;
  }
}
.ogt-link-arrow {
  &:before {
    content: $ogt-link-arrow;
  }
}
.ogt-checkmark-circle {
  &:before {
    content: $ogt-checkmark-circle;
  }
}
.ogt-checkmark {
  &:before {
    content: $ogt-checkmark;
  }
}
.ogt-close-search {
  &:before {
    content: $ogt-close-search;
  }
}
.ogt-logo-ostgotatrafiken {
  &:before {
    content: $ogt-logo-ostgotatrafiken;
  }
}
.ogt-contact-email {
  &:before {
    content: $ogt-contact-email;
  }
}
.ogt-contact-agents {
  &:before {
    content: $ogt-contact-agents;
  }
}
.ogt-contact-center {
  &:before {
    content: $ogt-contact-center;
  }
}
.ogt-contact-chat {
  &:before {
    content: $ogt-contact-chat;
  }
}
.ogt-contact-customer-care {
  &:before {
    content: $ogt-contact-customer-care;
  }
}
.ogt-contact-exchange {
  &:before {
    content: $ogt-contact-exchange;
  }
}
.ogt-contact-facebook {
  &:before {
    content: $ogt-contact-facebook;
  }
}
.ogt-contact-form {
  &:before {
    content: $ogt-contact-form;
  }
}
.ogt-contact-office {
  &:before {
    content: $ogt-contact-office;
  }
}
.ogt-contact-order {
  &:before {
    content: $ogt-contact-order;
  }
}
.ogt-contact-post {
  &:before {
    content: $ogt-contact-post;
  }
}
.ogt-shortcut-avatar {
  &:before {
    content: $ogt-shortcut-avatar;
  }
}
.ogt-shortcut-info {
  &:before {
    content: $ogt-shortcut-info;
  }
}
.ogt-shortcut-pin {
  &:before {
    content: $ogt-shortcut-pin;
  }
}
.ogt-shortcut-refill {
  &:before {
    content: $ogt-shortcut-refill;
  }
}
.ogt-shortcut-stop {
  &:before {
    content: $ogt-shortcut-stop;
  }
}
.ogt-shortcut-ticket {
  &:before {
    content: $ogt-shortcut-ticket;
  }
}
.ogt-shortcut-timetable {
  &:before {
    content: $ogt-shortcut-timetable;
  }
}
.ogt-shortcut-price {
  &:before {
    content: $ogt-shortcut-price;
  }
}
.ogt-shortcut-card {
  &:before {
    content: $ogt-shortcut-card;
  }
}
.ogt-chevron-left-16 {
  &:before {
    content: $ogt-chevron-left-16;
  }
}
.ogt-chevron-left-32 {
  &:before {
    content: $ogt-chevron-left-32;
  }
}
.ogt-chevron-right-32 {
  &:before {
    content: $ogt-chevron-right-32;
  }
}
.ogt-chevron-right-16 {
  &:before {
    content: $ogt-chevron-right-16;
  }
}
.ogt-arrow-left-16 {
  &:before {
    content: $ogt-arrow-left-16;
  }
}
.ogt-arrow-left-32 {
  &:before {
    content: $ogt-arrow-left-32;
  }
}
.ogt-arrow-right-32 {
  &:before {
    content: $ogt-arrow-right-32;
  }
}
.ogt-arrow-right-16 {
  &:before {
    content: $ogt-arrow-right-16;
  }
}
.ogt-arrow-down-16 {
  &:before {
    content: $ogt-arrow-down-16;
  }
}
.ogt-arrow-up-16 {
  &:before {
    content: $ogt-arrow-up-16;
  }
}
.ogt-list-remove-16 {
  &:before {
    content: $ogt-list-remove-16;
  }
}
.ogt-list-add-16 {
  &:before {
    content: $ogt-list-add-16;
  }
}
.ogt-contract-32 {
  &:before {
    content: $ogt-contract-32;
  }
}
.ogt-expand-32 {
  &:before {
    content: $ogt-expand-32;
  }
}
.ogt-contract-16 {
  &:before {
    content: $ogt-contract-16;
  }
}
.ogt-expand-16 {
  &:before {
    content: $ogt-expand-16;
  }
}
.ogt-close-32 {
  &:before {
    content: $ogt-close-32;
  }
}
.ogt-close-16 {
  &:before {
    content: $ogt-close-16;
  }
}
.ogt-clear-16 {
  &:before {
    content: $ogt-clear-16;
  }
}
.ogt-zoom-to-16 {
  &:before {
    content: $ogt-zoom-to-16;
  }
}
.ogt-menu-32 {
  &:before {
    content: $ogt-menu-32;
  }
}
.ogt-menu-16 {
  &:before {
    content: $ogt-menu-16;
  }
}
.ogt-checkbox-true-16 {
  &:before {
    content: $ogt-checkbox-true-16;
  }
}
.ogt-checkbox-false-16 {
  &:before {
    content: $ogt-checkbox-false-16;
  }
}
.ogt-radio-true-16 {
  &:before {
    content: $ogt-radio-true-16;
  }
}
.ogt-radio-false-16 {
  &:before {
    content: $ogt-radio-false-16;
  }
}
.ogt-translate {
  &:before {
    content: $ogt-translate;
  }
}
.ogt-add-16 {
  &:before {
    content: $ogt-add-16;
  }
}
.ogt-map-fullscreen-32 {
  &:before {
    content: $ogt-map-fullscreen-32;
  }
}
.ogt-map-minimize-32 {
  &:before {
    content: $ogt-map-minimize-32;
  }
}
.ogt-context-menu-16 {
  &:before {
    content: $ogt-context-menu-16;
  }
}
.ogt-map-menu-32 {
  &:before {
    content: $ogt-map-menu-32;
  }
}
.ogt-map-crosshair-32 {
  &:before {
    content: $ogt-map-crosshair-32;
  }
}
.ogt-show-16 {
  &:before {
    content: $ogt-show-16;
  }
}
.ogt-hide-16 {
  &:before {
    content: $ogt-hide-16;
  }
}
.ogt-system-16 {
  &:before {
    content: $ogt-system-16;
  }
}
.ogt-avatar {
  &:before {
    content: $ogt-avatar;
  }
}
.ogt-impersonate-16 {
  &:before {
    content: $ogt-impersonate-16;
  }
}
.ogt-edit-16 {
  &:before {
    content: $ogt-edit-16;
  }
}
.ogt-settings-32 {
  &:before {
    content: $ogt-settings-32;
  }
}
.ogt-warning-16 {
  &:before {
    content: $ogt-warning-16;
  }
}
.ogt-info-16 {
  &:before {
    content: $ogt-info-16;
  }
}
.ogt-critical-16 {
  &:before {
    content: $ogt-critical-16;
  }
}
.ogt-unknown-16 {
  &:before {
    content: $ogt-unknown-16;
  }
}
.ogt-login-email-32 {
  &:before {
    content: $ogt-login-email-32;
  }
}
.ogt-login-epi-32 {
  &:before {
    content: $ogt-login-epi-32;
  }
}
.ogt-login-facebook-32 {
  &:before {
    content: $ogt-login-facebook-32;
  }
}
.ogt-service-taxi-16 {
  &:before {
    content: $ogt-service-taxi-16;
  }
}
.ogt-service-taxi-32 {
  &:before {
    content: $ogt-service-taxi-32;
  }
}
.ogt-service-boat-16 {
  &:before {
    content: $ogt-service-boat-16;
  }
}
.ogt-service-boat-32 {
  &:before {
    content: $ogt-service-boat-32;
  }
}
.ogt-service-bus-16 {
  &:before {
    content: $ogt-service-bus-16;
  }
}
.ogt-service-bus-32 {
  &:before {
    content: $ogt-service-bus-32;
  }
}
.ogt-service-all-16 {
  &:before {
    content: $ogt-service-all-16;
  }
}
.ogt-service-all-32 {
  &:before {
    content: $ogt-service-all-32;
  }
}
.ogt-service-train-16 {
  &:before {
    content: $ogt-service-train-16;
  }
}
.ogt-service-train-32 {
  &:before {
    content: $ogt-service-train-32;
  }
}
.ogt-service-tram-16 {
  &:before {
    content: $ogt-service-tram-16;
  }
}
.ogt-service-tram-32 {
  &:before {
    content: $ogt-service-tram-32;
  }
}
.ogt-service-area-32 {
  &:before {
    content: $ogt-service-area-32;
  }
}
.ogt-service-traffic-light-32 {
  &:before {
    content: $ogt-service-traffic-light-32;
  }
}
.ogt-service-parking-32 {
  &:before {
    content: $ogt-service-parking-32;
  }
}
.ogt-service-quickomat-32 {
  &:before {
    content: $ogt-service-quickomat-32;
  }
}
.ogt-service-shop-32 {
  &:before {
    content: $ogt-service-shop-32;
  }
}
.ogt-play-32 {
  &:before {
    content: $ogt-play-32;
  }
}
.ogt-breadcrumb-16 {
  &:before {
    content: $ogt-breadcrumb-16;
  }
}
.ogt-menu-chevron-right {
  &:before {
    content: $ogt-menu-chevron-right;
  }
}
.ogt-link-away-16 {
  &:before {
    content: $ogt-link-away-16;
  }
}
.ogt-lock-16 {
  &:before {
    content: $ogt-lock-16;
  }
}
.ogt-unlock-16 {
  &:before {
    content: $ogt-unlock-16;
  }
}
.ogt-search {
  &:before {
    content: $ogt-search;
  }
}
.ogt-highlight-off-16 {
  &:before {
    content: $ogt-highlight-off-16;
  }
}
.ogt-highlight-on-16 {
  &:before {
    content: $ogt-highlight-on-16;
  }
}
.ogt-stop-16 {
  &:before {
    content: $ogt-stop-16;
  }
}
.ogt-mail {
  &:before {
    content: $ogt-mail;
  }
}
.ogt-service-overlay-phone {
  &:before {
    content: $ogt-service-overlay-phone;
  }
}
.ogt-service-overlay-square {
  &:before {
    content: $ogt-service-overlay-square;
  }
}
.ogt-service-overlay-warning {
  &:before {
    content: $ogt-service-overlay-warning;
  }
}
.ogt-service-overlay-triangle {
  &:before {
    content: $ogt-service-overlay-triangle;
  }
}
.ogt-service-overlay-info {
  &:before {
    content: $ogt-service-overlay-info;
  }
}
.ogt-service-overlay-circle {
  &:before {
    content: $ogt-service-overlay-circle;
  }
}
.ogt-audio {
  &:before {
    content: $ogt-audio;
  }
}
.ogt-print {
  &:before {
    content: $ogt-print;
  }
}
.ogt-share {
  &:before {
    content: $ogt-share;
  }
}
.ogt-social-youtube {
  &:before {
    content: $ogt-social-youtube;
  }
}
.ogt-social-facebook {
  &:before {
    content: $ogt-social-facebook;
  }
}
.ogt-social-twitter {
  &:before {
    content: $ogt-social-twitter;
  }
}
.ogt-social-instagram {
  &:before {
    content: $ogt-social-instagram;
  }
}
.ogt-social-linkedin {
  &:before {
    content: $ogt-social-linkedin;
  }
}
.ogt-warning-32 {
  &:before {
    content: $ogt-warning-32;
  }
}
.ogt-forecast-16 {
  &:before {
    content: $ogt-forecast-16;
  }
}
.ogt-timetable-16 {
  &:before {
    content: $ogt-timetable-16;
  }
}
.ogt-clock-16 {
  &:before {
    content: $ogt-clock-16;
  }
}
.ogt-switch-mobile-32 {
  &:before {
    content: $ogt-switch-mobile-32;
  }
}
.ogt-switch-32 {
  &:before {
    content: $ogt-switch-32;
  }
}
.ogt-dropdown-16 {
  &:before {
    content: $ogt-dropdown-16;
  }
}
.ogt-date-16 {
  &:before {
    content: $ogt-date-16;
  }
}
.ogt-pin-stop-32 {
  &:before {
    content: $ogt-pin-stop-32;
  }
}
.ogt-pin-stop-16 {
  &:before {
    content: $ogt-pin-stop-16;
  }
}
.ogt-pin-star-32 {
  &:before {
    content: $ogt-pin-star-32;
  }
}
.ogt-pin-outline-32 {
  &:before {
    content: $ogt-pin-outline-32;
  }
}
.ogt-pin-outline-16 {
  &:before {
    content: $ogt-pin-outline-16;
  }
}
.ogt-pin-dot-16 {
  &:before {
    content: $ogt-pin-dot-16;
  }
}
.ogt-pin-dot-32 {
  &:before {
    content: $ogt-pin-dot-32;
  }
}
.ogt-pin-travel-to-32 {
  &:before {
    content: $ogt-pin-travel-to-32;
  }
}
.ogt-pin-travel-from-32 {
  &:before {
    content: $ogt-pin-travel-from-32;
  }
}
.ogt-Capslock2 {
  &:before {
    content: $ogt-Capslock2;
  }
}
