html,
body {
  background-color: $lightblue;
  font-family: "HelveticaNeueW01-55Roma";
  color: $text;
  font-size: 16px;
}

ul {
  &:not(.file-list) {
    list-style: disc;
    padding-left: 30px;
    margin-bottom: 24px;

    font-size: 18px;
    line-height: 32px;
  }

  li {
    margin-bottom: 8px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: "bitterbold";
}
.show-for-sr {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

h1,
.h1 {
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 24px;

  @media only screen and (min-width: $md) {
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 40px;
  }
}

h2,
.h2 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;

  &:focus {
    outline: 0;
  }

  @media only screen and (min-width: $md) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 32px;
  }
}

h3,
.h3 {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 8px;

  @media only screen and (min-width: $md) {
    margin-bottom: 16px;
  }
}

h4,
.h4 {
  font-size: 16px;
  line-height: 32px;
}

h5,
.h5 {
  font-size: 18px;
  line-height: 29px;
}

h6,
.h6 {
  font-size: 16px;
  line-height: 26px;
}

p {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 24px;
  color: $secondary-text;
  font-family: "HelveticaNeueW01-45Ligh";
}

p,
li,
ol {
  font-family: "HelveticaNeueW01-45Ligh";

  a {
    text-decoration: underline;
    color: $ot-red-darker;

    &:hover {
      text-decoration: none;
    }
  }
}

.box {
  p,
  li,
  ol {
    a {
      color: $ot-red;
    }
  }
}

.hide {
  display: none !important;
}

a,
button {
  &:focus-visible {
    outline: 3px solid #004f6f;
  }
}

.footer {
  a,
  button {
    &:focus-visible {
      outline-color: #ffffff;
    }
  }
}

a {
  &:hover {
    text-decoration: underline;
  }
}

.container {
  padding-left: 1rem;
  padding-right: 1rem;
}

.show {
  display: block !important;
}

#skiplinks a {
  position: absolute;
  top: -2012px;
  display: block;

  &:focus {
    position: absolute;
    top: 10px;
    left: 10px;
    display: inline-block;
    background-color: $ot-red;
    color: #fff;
    padding: 12px;
    white-space: nowrap;
    z-index: 1;
    border: 2px solid #fff;
    outline-offset: 0;
  }
}

hr {
  height: 3px;
  background-color: #d9d9d9;
}
