$black: #000;

$text: #222;
$secondary-text: #333;

//Red
$ot-red: #e10e1c;
$ot-red-darker: #b10b16;

//Black
$almostblack: #222222;

//Yellow
$yellow: #ffdd00;

//Gray
$darkgrey: #767676;
$gray: #e6e6e6;

$lightorange: #fcf0e7;
$lightgray: #f2f0ef;
$lightpink: #fce4e5;
$lightblue: #f1f4f8;

//Green
$green: #00b650;

//Hovers
$primary-red-hover: #ad0b16;
$primary-red-active: #7a080f;

$secondary-red-hover: #fcf0e7;
$secondary-red-active: #f6d7bb;

// Forms
$focus: #004f6f;
$hover: #868686;

//icons$icomoon-font-family: "ogt-icons" !default;
$icomoon-font-path: "fonts" !default;
$ogt-PDF: "\e900";
$ogt-info-uppdaterad-2: "\e90c";
$ogt-info-uppdaterad: "\e90d";
$ogt-service-walk-32: "\e90b";
$ogt-map-icon: "\e904";
$ogt-shortcut-card-big: "\e905";
$ogt-accessibility: "\e906";
$ogt-info-circle: "\e907";
$ogt-extern-link-arrow: "\e908";
$ogt-link-arrow: "\e909";
$ogt-checkmark-circle: "\e90a";
$ogt-checkmark: "\ea19";
$ogt-close-search: "\e90e";
$ogt-logo-ostgotatrafiken: "\ea1a";
$ogt-contact-email: "\e9d5";
$ogt-contact-agents: "\e9d6";
$ogt-contact-center: "\e9d7";
$ogt-contact-chat: "\e9d8";
$ogt-contact-customer-care: "\e9d9";
$ogt-contact-exchange: "\e9df";
$ogt-contact-facebook: "\e9e2";
$ogt-contact-form: "\e9e4";
$ogt-contact-office: "\e9e6";
$ogt-contact-order: "\e9e8";
$ogt-contact-post: "\e9e9";
$ogt-shortcut-avatar: "\e9f1";
$ogt-shortcut-info: "\e9f3";
$ogt-shortcut-pin: "\ea0b";
$ogt-shortcut-refill: "\ea0c";
$ogt-shortcut-stop: "\ea0e";
$ogt-shortcut-ticket: "\ea13";
$ogt-shortcut-timetable: "\ea1b";
$ogt-shortcut-price: "\ea1c";
$ogt-shortcut-card: "\ea53";
$ogt-chevron-left-16: "\e911";
$ogt-chevron-left-32: "\e910";
$ogt-chevron-right-32: "\ea6a";
$ogt-chevron-right-16: "\ea6b";
$ogt-arrow-left-16: "\ea1d";
$ogt-arrow-left-32: "\ea1f";
$ogt-arrow-right-32: "\ea74";
$ogt-arrow-right-16: "\ea75";
$ogt-arrow-down-16: "\ea22";
$ogt-arrow-up-16: "\ea2a";
$ogt-list-remove-16: "\ea33";
$ogt-list-add-16: "\ea3b";
$ogt-contract-32: "\ea3c";
$ogt-expand-32: "\ea3e";
$ogt-contract-16: "\ea3f";
$ogt-expand-16: "\ea40";
$ogt-close-32: "\ea6e";
$ogt-close-16: "\ea6f";
$ogt-clear-16: "\ea41";
$ogt-zoom-to-16: "\ea42";
$ogt-menu-32: "\ea71";
$ogt-menu-16: "\ea76";
$ogt-checkbox-true-16: "\ea77";
$ogt-checkbox-false-16: "\ea78";
$ogt-radio-true-16: "\ea58";
$ogt-radio-false-16: "\ea5c";
$ogt-translate: "\ea43";
$ogt-add-16: "\ea44";
$ogt-map-fullscreen-32: "\ea46";
$ogt-map-minimize-32: "\ea47";
$ogt-context-menu-16: "\ea48";
$ogt-map-menu-32: "\ea4a";
$ogt-map-crosshair-32: "\ea4b";
$ogt-show-16: "\ea4c";
$ogt-hide-16: "\ea4d";
$ogt-system-16: "\ea4e";
$ogt-avatar: "\ea79";
$ogt-impersonate-16: "\ea4f";
$ogt-edit-16: "\ea50";
$ogt-settings-32: "\ea51";
$ogt-warning-16: "\ea54";
$ogt-info-16: "\ea61";
$ogt-critical-16: "\ea7a";
$ogt-unknown-16: "\ea7b";
$ogt-login-email-32: "\ea7c";
$ogt-login-epi-32: "\ea7d";
$ogt-login-facebook-32: "\ea7e";
$ogt-service-taxi-16: "\ea7f";
$ogt-service-taxi-32: "\ea80";
$ogt-service-boat-16: "\ea81";
$ogt-service-boat-32: "\ea82";
$ogt-service-bus-16: "\ea83";
$ogt-service-bus-32: "\ea84";
$ogt-service-all-16: "\ea85";
$ogt-service-all-32: "\ea86";
$ogt-service-train-16: "\ea87";
$ogt-service-train-32: "\ea88";
$ogt-service-tram-16: "\ea89";
$ogt-service-tram-32: "\ea8a";
$ogt-service-area-32: "\ea8d";
$ogt-service-traffic-light-32: "\e9be";
$ogt-service-parking-32: "\ea8e";
$ogt-service-quickomat-32: "\ea8f";
$ogt-service-shop-32: "\ea90";
$ogt-play-32: "\ea91";
$ogt-breadcrumb-16: "\ea92";
$ogt-menu-chevron-right: "\ea93";
$ogt-link-away-16: "\ea94";
$ogt-lock-16: "\ea95";
$ogt-unlock-16: "\ea96";
$ogt-search: "\ea97";
$ogt-highlight-off-16: "\ea98";
$ogt-highlight-on-16: "\ea99";
$ogt-stop-16: "\ea9a";
$ogt-mail: "\ea9b";
$ogt-service-overlay-phone: "\ea9c";
$ogt-service-overlay-square: "\ea9d";
$ogt-service-overlay-warning: "\ea9e";
$ogt-service-overlay-triangle: "\ea9f";
$ogt-service-overlay-info: "\eaa0";
$ogt-service-overlay-circle: "\eaa1";
$ogt-audio: "\eaa2";
$ogt-print: "\eaa3";
$ogt-share: "\eaa4";
$ogt-social-youtube: "\eaa5";
$ogt-social-facebook: "\eaa6";
$ogt-social-twitter: "\eaa7";
$ogt-social-instagram: "\eaa8";
$ogt-social-linkedin: "\eaa9";
$ogt-warning-32: "\eaaa";
$ogt-forecast-16: "\eaab";
$ogt-timetable-16: "\eaac";
$ogt-clock-16: "\eaad";
$ogt-switch-mobile-32: "\eaae";
$ogt-switch-32: "\eaaf";
$ogt-dropdown-16: "\eab0";
$ogt-date-16: "\eab1";
$ogt-pin-stop-32: "\eab2";
$ogt-pin-stop-16: "\eab3";
$ogt-pin-star-32: "\eab4";
$ogt-pin-outline-32: "\eab5";
$ogt-pin-outline-16: "\eab6";
$ogt-pin-dot-16: "\eab7";
$ogt-pin-dot-32: "\eab8";
$ogt-pin-travel-to-32: "\e9bf";
$ogt-pin-travel-from-32: "\eab9";
$ogt-Capslock2: "\e90f";
