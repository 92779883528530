.fileupload {
  .dropzone {
    width: 100%;
    text-align: left;

    // &.disabled {
    //   // display: none;
    //   > div {
    //     cursor: default;
    //     border: 2px dashed $darkgrey;
    //     @media only screen and (min-width: $md) {
    //       border: 1px solid $darkgrey;
    //       color: $darkgrey;
    //     }
    //     span.click-here {
    //       color: $darkgrey;
    //       text-decoration: none;
    //     }
    //     &:hover {
    //       background-color: transparent;
    //     }
    //     svg {
    //       color: $darkgrey;
    //     }
    //   }
    // }

    &:focus {
      > div {
        background-color: $secondary-red-hover;
      }
    }
    > div {
      padding: 20px;
      border-radius: 15px;
      font-size: 18px;
      line-height: 32px;
      padding: 24px 8px;
      font-family: "HelveticaNeueW01-55Roma";
      cursor: pointer;
      position: relative;
      border: 2px dashed $ot-red;

      // display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      @media only screen and (min-width: $md) {
        border: 1px solid $ot-red;
        padding: 40px 24px;
        padding-left: 77px;
      }

      &:hover {
        background-color: $secondary-red-hover;

        span.click-here {
          text-decoration: none;
        }
      }

      .mobile-photo {
        display: flex;
        align-items: center;
        width: 100%;
        display: flex;
        justify-content: center;
        color: $ot-red;
        text-decoration: underline;
        font-family: "HelveticaNeueW01-65Medi";
        min-height: 113px;

        svg {
          top: -1px;
          font-size: 25px;
          margin-bottom: 0;
          margin-right: 16px;
        }

        &.choose-file {
          svg {
            margin-right: 9px;
          }
        }
      }

      .take-picture {
        font-size: 16px;
        line-height: 21px;
      }
    }

    &:focus-visible {
      outline-offset: 5px;
    }

    svg {
      display: flex;
      align-items: center;
      left: 24px;
      top: 0;
      bottom: 0;
      font-size: 40px;
      margin-bottom: 12px;

      @media only screen and (min-width: $md) {
        position: absolute;
        margin: auto;
        margin-right: 24px;
      }
    }

    span.click-here {
      font-family: "HelveticaNeueW01-65Medi";
      color: $ot-red;
      display: inline;
      width: 100%;

      @media only screen and (min-width: $md) {
        text-decoration: underline;
        margin-right: 10px;
        width: auto;
      }
    }
  }

  h3 {
    margin-bottom: 0;
  }

  .validation-error {
    margin-top: 0;
  }

  // .rejected-file {
  //   font-style: italic;
  //   font-size: 14px;
  //   line-height: 21px;
  //   padding: 0 24px;

  //   @media only screen and (min-width: $md) {
  //     font-size: 18px;
  //     line-height: 26px;
  //   }
  // }

  .file-list {
    // margin-top: 8px;
    li {
      margin-bottom: 15px;

      .uploading {
        font-family: "HelveticaNeueW01-65Medi";
        border: 1px solid #b8babd;
        font-size: 14px;
        line-height: 16px;
        padding: 8px;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;

        @media only screen and (min-width: $md) {
          font-size: 18px;
          line-height: 32px;
          padding: 12px 40px;
        }
      }

      .file {
        font-size: 14px;
        line-height: 16px;
        font-family: "HelveticaNeueW01-65Medi";
        background-color: $lightgray;
        border-radius: 25px;
        padding: 12px 24px;
        color: $black;

        &.error {
          outline: 2px solid $ot-red;
        }

        @media only screen and (min-width: $md) {
          font-size: 18px;
          line-height: 32px;
        }

        .file-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 360px;
          padding-right: 8px;
        }
        button {
          font-family: "HelveticaNeueW01-65Medi";

          font-size: 14px;
          line-height: 16px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

body {
  .file-label {
    margin-bottom: 8px;
  }
}
