@import "../variables/variables";

.header {
  background-color: #ffffff;
  border-bottom: 8px solid $ot-red;

  a {
    font-size: 14px;
    color: #333;
    font-family: "HelveticaNeueW01-45Ligh";
    @media only screen and (min-width: 400px) {
      font-size: 18px;
    }

    span.translate {
      margin-bottom: 3px;
    }

    i {
      font-size: 14px;
      top: 1px;

      @media only screen and (min-width: 400px) {
        font-size: 24px;
        top: 4px;
      }
    }
  }

  .logo {
    width: 200px;

    @media only screen and (min-width: 400px) {
      width: 216px;
    }
  }
}
