.box {
  position: relative;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 24px 8px;
  padding-top: 40px;
  margin-bottom: 40px;

  @media only screen and (min-width: $md) {
    padding: 40px 24px;
  }

  .inner {
    max-width: 540px;

    &.fullwidth {
      max-width: none;
    }
  }

  h2 {
    margin-bottom: 24px;
  }

  .steps {
    font-family: "HelveticaNeueW01-45Ligh";
    color: $secondary-text;
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 14px;
    line-height: 32px;

    @media only screen and (min-width: $md) {
      right: 24px;
    }
  }
}
