.summary-section {
  background-color: $lightblue;
  padding: 16px;
  margin-bottom: 24px;

  h3 {
    // margin-top: 16px;
    margin-bottom: 8px;
    line-height: 24px;

    @media only screen and (min-width: $md) {
      line-height: 32px;
    }
  }

  .summary-data-label {
    font-family: "bittermedium";
    font-size: 14px;
    line-height: 16px;
    display: flex;
    margin-bottom: 4px;

    &.last {
      margin-bottom: 0;
    }

    @media only screen and (min-width: $md) {
      font-size: 16px;
      line-height: 32px;
      margin-bottom: 32px;
    }
  }

  .summary-data {
    display: flex;
    font-family: "HelveticaNeueW01-65Medi";
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
    overflow-wrap: anywhere;

    &.last {
      margin-bottom: 0;
    }

    @media only screen and (min-width: $md) {
      line-height: 32px;
      margin-bottom: 32px;
    }
  }

  .summary-edit,
  .summary-remove {
    button.btn.stripped {
      color: $secondary-text;
      font-size: 14px;
      line-height: 22px;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .summary-remove {
    text-align: left;
  }
}
