/* This stylesheet generated by Transfonter (http://transfonter.org) on February 15, 2016 2:04 PM */

$default-font-path: "../../assets/Fonts" !default;

.sans-medium {
  font-family: "HelveticaNeueW01-55Roma";
}
.sans-bold {
  font-family: "HelveticaNeueW01-65Medi";
}
.bitter-bold {
  font-family: "bitterbold";
}

@font-face {
  font-family: "HelveticaNeueW01-45Ligh";
  src: url("#{$default-font-path}/ae1656aa-5f8f-4905-aed0-93e667bd6e4a.eot?#iefix");
  src: url("#{$default-font-path}/ae1656aa-5f8f-4905-aed0-93e667bd6e4a.eot?#iefix")
      format("eot"),
    url("#{$default-font-path}/5a13f7d1-b615-418e-bc3a-525001b9a671.woff2")
      format("woff2"),
    url("#{$default-font-path}/530dee22-e3c1-4e9f-bf62-c31d510d9656.woff")
      format("woff"),
    url("#{$default-font-path}/688ab72b-4deb-4e15-a088-89166978d469.ttf")
      format("truetype"),
    url("#{$default-font-path}/7816f72f-f47e-4715-8cd7-960e3723846a.svg#7816f72f-f47e-4715-8cd7-960e3723846a")
      format("svg");
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueW01-45Ligh";
  src: url("#{$default-font-path}/a4f9f319-dec9-4afc-8b50-e18cc1b61cb4.eot?#iefix");
  src: url("#{$default-font-path}/a4f9f319-dec9-4afc-8b50-e18cc1b61cb4.eot?#iefix")
      format("eot"),
    url("#{$default-font-path}/09f28057-e848-49b3-94bf-bfaefff51b64.woff2")
      format("woff2"),
    url("#{$default-font-path}/8e9ee02b-f337-4f13-ad6c-969d6d6536c8.woff")
      format("woff"),
    url("#{$default-font-path}/beddbbc6-203e-4f36-8960-3f5ca22af567.ttf")
      format("truetype"),
    url("#{$default-font-path}/af177436-d9e8-44e8-9c9c-ef2d945f20a3.svg#af177436-d9e8-44e8-9c9c-ef2d945f20a3")
      format("svg");
  font-style: italic;
}
@font-face {
  font-family: "HelveticaNeueW01-55Roma";
  src: url("#{$default-font-path}/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix");
  src: url("#{$default-font-path}/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix")
      format("eot"),
    url("#{$default-font-path}/b0868b4c-234e-47d3-bc59-41ab9de3c0db.woff2")
      format("woff2"),
    url("#{$default-font-path}/bcf54343-d033-41ee-bbd7-2b77df3fe7ba.woff")
      format("woff"),
    url("#{$default-font-path}/b0ffdcf0-26da-47fd-8485-20e4a40d4b7d.ttf")
      format("truetype"),
    url("#{$default-font-path}/da09f1f1-062a-45af-86e1-2bbdb3dd94f9.svg#da09f1f1-062a-45af-86e1-2bbdb3dd94f9")
      format("svg");
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueW01-55Roma";
  src: url("#{$default-font-path}/58a5cbff-d570-4c18-a5e3-60868dc07ae8.eot?#iefix");
  src: url("#{$default-font-path}/58a5cbff-d570-4c18-a5e3-60868dc07ae8.eot?#iefix")
      format("eot"),
    url("#{$default-font-path}/462d508d-efc7-478f-b84e-288eeb69a160.woff2")
      format("woff2"),
    url("#{$default-font-path}/2c056da8-4920-4e20-8c69-8a6b315458a9.woff")
      format("woff"),
    url("#{$default-font-path}/2381d918-136d-444f-8391-db0cba6da388.ttf")
      format("truetype"),
    url("#{$default-font-path}/d0697971-6f58-4597-942e-8beabd1adc87.svg#d0697971-6f58-4597-942e-8beabd1adc87")
      format("svg");
  font-style: italic;
}
@font-face {
  font-family: "HelveticaNeueW01-65Medi";
  src: url("#{$default-font-path}/07fe0fec-b63f-4963-8ee1-535528b67fdb.eot?#iefix");
  src: url("#{$default-font-path}/07fe0fec-b63f-4963-8ee1-535528b67fdb.eot?#iefix")
      format("eot"),
    url("#{$default-font-path}/3600b37f-2bf1-45f3-be3a-03365f16d9cb.woff2")
      format("woff2"),
    url("#{$default-font-path}/60be5c39-863e-40cb-9434-6ebafb62ab2b.woff")
      format("woff"),
    url("#{$default-font-path}/4c6503c9-859b-4d3b-a1d5-2d42e1222415.ttf")
      format("truetype"),
    url("#{$default-font-path}/36c182c6-ef98-4021-9b0d-d63122c2bbf5.svg#36c182c6-ef98-4021-9b0d-d63122c2bbf5")
      format("svg");
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueW01-65Medi";
  src: url("#{$default-font-path}/71c74bc5-e7c8-48ad-9b03-43f3e6760e4e.eot?#iefix");
  src: url("#{$default-font-path}/71c74bc5-e7c8-48ad-9b03-43f3e6760e4e.eot?#iefix")
      format("eot"),
    url("#{$default-font-path}/9a31d352-a4be-4540-a6f2-f4ea3ea77d94.woff2")
      format("woff2"),
    url("#{$default-font-path}/5f0efe11-b07a-49fc-ab86-7af6152fa368.woff")
      format("woff"),
    url("#{$default-font-path}/fea58e5b-b0a7-43f7-8928-fc0639365852.ttf")
      format("truetype"),
    url("#{$default-font-path}/398e1bb8-f2eb-41d5-b3b6-b9a889b52ee7.svg#398e1bb8-f2eb-41d5-b3b6-b9a889b52ee7")
      format("svg");
  font-style: italic;
}

// BitterPRO - Bold
@font-face {
  font-family: "bitterbold";
  src: url("#{$default-font-path}/BitterProOGT-Bold.woff2") format("woff2"),
    url("#{$default-font-path}/BitterProOGT-Bold.woff") format("woff"),
    url("#{$default-font-path}/BitterProOGT-Bold.eot?#iefix") format("eot");
  font-weight: normal;
  font-style: normal;
}

// BitterPRO - Light
@font-face {
  font-family: "bitterlight";
  src: url("#{$default-font-path}/BitterProOGT-Light.woff2") format("woff2"),
    url("#{$default-font-path}/BitterProOGT-Light.woff") format("woff"),
    url("#{$default-font-path}/BitterProOGT-Light.eot?#iefix") format("eot");
  font-weight: normal;
  font-style: normal;
}

// BitterPRO - Thin
@font-face {
  font-family: "bitterthin";
  src: url("#{$default-font-path}/BitterProOGT-Thin.woff2") format("woff2"),
    url("#{$default-font-path}/BitterProOGT-Thin.woff") format("woff"),
    url("#{$default-font-path}/BitterProOGT-Thin.eot?#iefix") format("eot");
  font-weight: normal;
  font-style: normal;
}

// BitterPRO - Medium
@font-face {
  font-family: "bittermedium";
  src: url("#{$default-font-path}/BitterProOGT-Medium.woff2") format("woff2"),
    url("#{$default-font-path}/BitterProOGT-Medium.woff") format("woff"),
    url("#{$default-font-path}/BitterProOGT-Medium.eot?#iefix") format("eot");
  font-weight: normal;
  font-style: normal;
}
