body {
  .btn {
    &:focus-visible {
      outline-offset: 4px;
    }
  }
  .btn {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    font-family: "HelveticaNeueW01-65Medi";
    padding: 4px 24px;

    @media only screen and (min-width: $md) {
      font-size: 18px;
      padding: 12px 40px;
      line-height: 32px;
    }

    &:focus-visible {
      outline-offset: 4px;
    }

    &.primary,
    &.secondary {
      border-radius: 50px;
    }

    &.primary {
      background-color: $ot-red;
      color: #ffffff;

      &:hover {
        background-color: $primary-red-hover;
        text-decoration: underline;
      }

      &:active {
        background-color: $primary-red-active;
      }
    }

    &.secondary {
      background-color: #ffffff;
      color: $ot-red;
      border: 1px solid $ot-red;

      &:hover {
        background-color: $secondary-red-hover;
        color: $ot-red-darker;
      }
      &:active {
        background-color: $secondary-red-active;
      }
    }

    &.stripped {
      padding-left: 0;
      padding-right: 0;
      padding: 4px;
      color: $ot-red-darker;
      background-color: transparent;

      svg {
        color: $ot-red-darker;
        font-size: 16px;
      }
    }
  }
}
